
import { Component, Vue, Watch } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import defaultAvatar from '@/assets/img/picture.png'
import Fullscreen from './Fullscreen.vue'
@Component({
  name: 'ProjectArea',
  components: { Fullscreen }
})
export default class extends Vue {
  currentIndex = 0
  tabIndex = 3
  bzTabIndex = 0
  projectList = []
  areaList = []
  currentProjectId: any = ''
  currentAreaName = ''
  currentMonth = ''
  currentDay = ''
  dayArr: any = []

  signList: any = []

  dialogVisible = false

  yhst = {
    dzgIssueCount: 0,
    issueCount: 0,
    pointUploadList: []
  }

  pickerOptions = {
    disabledDate: (date: any) => {
      return this.$dayjs().month() < this.$dayjs(date).month() && this.$dayjs().year() <= this.$dayjs(date).year()
    }
  }

  yhst2 = {
    orderCount: 0,
    orderWwcCount: 0,
    orderList: []
  }

  yhbz: any = {
    liableUserImg: {},
    conserveLevel: '',
    liableUserName: '',
    conserveElementMap: [],
    conservePointList: [],
    conserveDesc: ''
  }

  maxMonth = 0

  map: any = null

  ploygons: any = []

  get userAvatar () {
    if (this.yhbz.liableUserImg) {
      return this.yhbz.liableUserImg.filePrefix + this.yhbz.liableUserImg.fileUrl
    } else {
      return defaultAvatar
    }
  }

  get projectId () {
    return this.$route.query.projectId
  }

  get currentAreaId () {
    return this.$route.query.areaId
  }

  created () {
    // do something
    this.maxMonth = +this.$dayjs().format('M')
  }

  @Watch('tabIndex')
  tabIndexChange () {
    if (this.tabIndex !== 2) {
      this.getProjectLocation()
      this.initMonth(this.currentMonth)
      if (this.tabIndex === 3) {
        this.loadProjectAreaYhDateOrder()
      } else if (this.tabIndex === 1) {
        this.loadSignData()
      }
    }
  }

  @Watch('currentAreaId')
  currentAreaIdChange () {
    this.areaChange()
  }

  mounted () {
    this.loadMap()
    this.currentDay = this.$dayjs().format('MM-DD')
    this.initMonth(this.$dayjs().format('YYYY-MM'))
    this.loadAreaList()
  }

  loadAreaList () {
    this.$axios.get(this.$apis.grid.selectYhProjectAreaSumList, {
      projectId: this.projectId
    }).then(res => {
      this.areaList = res || []
      this.$nextTick(() => {
        const element = document.querySelector('#id' + this.currentAreaId)
        if (element) {
          element.scrollIntoView()
        }
      })
    })
  }

  loadSignData () {
    this.$axios.get(this.$apis.grid.selectProjectAreaYhDatePoint, {
      projectAreaId: this.currentAreaId,
      cDate: this.currentMonth.split('-')[0] + '.' + this.currentDay.replace('-', '.')
    }).then(res => {
      this.yhst = res || {}
    })
  }

  toDetail () {
    this.$router.push(`/patrol/patrolIssue?projectId=${this.currentProjectId}&date=${this.currentMonth.split('-')[0] + '-' + this.currentDay}`)
  }

  toDetail2 (areaId: string) {
    this.$router.replace(`/grid/detail?areaId=${areaId}&projectId=${this.projectId}`)
  }

  loadProjectAreaYhDateOrder () {
    this.$axios.get(this.$apis.grid.selectProjectAreaYhDateOrder, {
      projectAreaId: this.currentAreaId,
      cDate: this.currentMonth.split('-')[0] + '.' + this.currentDay.replace('-', '.')
    }).then(res => {
      (res || {}).orderList.forEach((item: any) => {
        item.fbFileUploadList = item.fbFileUploadList.filter((file: any) => {
          return file.fileType !== 'mp4'
        })
      })
      this.yhst2 = res || {}
      this.$nextTick(() => {
        const element = document.querySelector('#id' + this.currentDay)
        if (element) {
          element.scrollIntoView()
        }
      })
      // this.map.clearMap()
      const markersAll = this.map.getAllOverlays('marker')
      this.map.remove(markersAll)
      const markers: any = []
      this.yhst2.orderList.map((item: any, index: number) => {
        if (item.longitude && item.latitude) {
          const marker = new AMap.Marker({
            position: [+item.longitude, +item.latitude],
            cursor: 'default',
            icon: new AMap.Icon({
              size: [36, 36],
              image: item.orderStatus === '2' ? require('@/assets/img/grid/order-on.svg') : require('@/assets/img/grid/order-off.svg'),
              imageSize: [36, 36]
            }),
            offset: new AMap.Pixel(-18, -18),
            label: {
              content: item.fbTime.split(' ')[1],
              direction: 'top'
            }
          })
          markers.push(marker)
        }
      })
      markers.forEach((marker: any) => {
        (marker as any).dom.children[1].style.borderRadius = '4px';
        (marker as any).dom.children[1].style.border = 'none';
        (marker as any).dom.children[1].style.padding = '4px 8px';
        (marker as any).dom.children[1].style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
        (marker as any).dom.children[1].style.fontSize = '14px';
        (marker as any).dom.children[1].style.fontWeight = '400';
        (marker as any).dom.children[1].style.color = '#FFFFFF';
        (marker as any).dom.children[1].style.transform = 'translate3d(0, -5px, 0)'
      })
      this.map.add(markers)
    })
  }

  tabChange (index: any) {
    this.bzTabIndex = index
    const el = document.querySelector('#bzTab') as Element
    (el.childNodes[index] as any).scrollIntoView({ behavior: 'smooth', inline: 'center' })
  }

  changeLayer (value: number) {
    this.currentIndex = value
    this.map.setLayers([])
    if (value === 0) {
      // 添加标准地图图层
      this.map.setMapStyle('amap://styles/normal')
      this.map.add(new AMap.TileLayer())
    } else {
      // 添加卫星地图图层
      this.map.add(new AMap.TileLayer.Satellite())
    }
  }

  loadAreaYhStandard () {
    this.$axios.get(this.$apis.grid.selectProjectAreaYhStandard, {
      projectAreaId: this.currentAreaId
    }).then(res => {
      const conserveElementMap = []
      for (const key in res.conserveElementMap) {
        conserveElementMap.push({
          key: key,
          value: res.conserveElementMap[key]
        })
      }
      res.conserveElementMap = conserveElementMap
      this.yhbz = res
    })
  }

  loadMap () {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      this.map = new AMap.Map('map', {
        zoom: 11,
        doubleClickZoom: false
      })
      this.areaChange()
      // this.loadProjectList()
      // this.markerListAdd()
      // this.isChange && this.map.on('click', (e) => {
      //   this.markerClick([e.lnglat.getLng(), e.lnglat.getLat()])
      // })
    })
  }

  loadProjectList () {
    this.$axios.get(this.$apis.grid.selectProjectAreaYhList).then(res => {
      this.projectList = res
      this.projectChange(res[0])
      // this.areaChange(res[0].projectAreaList[0])
    })
  }

  projectChange (item: any) {
    this.currentProjectId = item.projectId
    this.areaList = item.projectAreaList
  }

  areaChange () {
    // this.currentAreaId = item.projectAreaId
    // this.currentAreaName = item.projectAreaName
    this.getProjectLocation().then(() => {
      if (this.tabIndex === 3) {
        this.loadProjectAreaYhDateOrder()
      }
    })
    this.currentDay = this.$dayjs().format('MM-DD')
    this.loadSignData()
    this.loadAreaYhStandard()
  }

  initMonth (data: any) {
    const month = parseInt(data.split('-')[1])
    console.log('month', month)
    this.currentMonth = data
    // const dayArr = []
    // const days = +this.$dayjs().month(month - 1).endOf('month').format('D')
    // for (let index = 1; index <= days; index++) {
    //   dayArr.push(`${String(month).padStart(2, '0')}-${String(index).padStart(2, '0')}`)
    // }
    // this.dayArr = dayArr

    if (this.tabIndex === 1) {
      this.$axios.get(this.$apis.grid.selectProjectAreaYhDateList, {
        projectAreaId: this.currentAreaId,
        month: data
      }).then((res) => {
        this.dayArr = res.map((item: any) => {
          item.cDate = item.cDate.replace('.', '-')
          return item
        })
        this.$nextTick(() => {
          const element = document.querySelector('#id' + this.currentDay)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', inline: 'center' })
          }
        })
      })
    } else if (this.tabIndex === 3) {
      this.$axios.get(this.$apis.grid.selectProjectAreaYhDateOrderList, {
        projectAreaId: this.currentAreaId,
        month: data
      }).then((res) => {
        this.dayArr = res.map((item: any) => {
          item.cDate = item.cDate.replace('.', '-')
          return item
        })
        this.$nextTick(() => {
          const element = document.querySelector('#id' + this.currentDay)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', inline: 'center' })
          }
        })
      })
    }
  }

  changeDate (date: any) {
    this.currentDay = date
    if (this.tabIndex === 3) {
      this.loadProjectAreaYhDateOrder()
    } else {
      this.loadSignData()
      this.getProjectLocation()
    }
    (document.querySelector('#id' + this.currentDay) as Element).scrollIntoView({ behavior: 'smooth', inline: 'center' })
  }

  // 获取项目电子围栏
  getProjectLocation () {
    console.log('执行了电子围栏接口')
    return this.$axios.get(this.$apis.project.selectYhProjectAreaByProjectAreaId, {
      projectId: this.currentProjectId,
      projectAreaId: this.currentAreaId,
      cDate: this.currentMonth.split('-')[0] + '.' + this.currentDay.replace('-', '.')
    }).then((res) => {
      this.currentProjectId = res.projectId
      this.map.clearMap()
      this.ploygons = drawPolygon(this.map, res.locationList, null, (e: any) => {
        console.log('e', e)
        // this.isChange && this.markerClick([e.lnglat.getLng(), e.lnglat.getLat()])
      })
      this.map.setFitView(this.ploygons, true, [60, 450, 60, 60])
      if (this.tabIndex === 1) {
        this.addMarkers(res.conservePointList)
      }
    })
  }

  addMarkers (points: any) {
    const markers = points.map((item: any) => {
      return new AMap.Marker({
        position: [+item.longitude, +item.latitude],
        cursor: 'pointer',
        icon: new AMap.Icon({
          size: [36, 36],
          image: item.isTodaySign === '1' ? require('@/assets/img/grid/patrol-on.svg') : require('@/assets/img/grid/patrol-off.svg'),
          imageSize: [36, 36]
        }),
        offset: new AMap.Pixel(-18, -18),
        label: {
          direction: 'top',
          content: item.objectName
        },
        extData: {
          objectId: item.obejctId
        }
      })
    })
    console.log('markers', markers)
    // markers.forEach((item: any) => {
    //   item.dom.childNodes[1].classList.add('grid-point-label')
    // })
    markers.forEach((marker: any) => {
      (marker as any).dom.children[1].style.borderRadius = '4px';
      (marker as any).dom.children[1].style.border = 'none';
      (marker as any).dom.children[1].style.padding = '4px 8px';
      (marker as any).dom.children[1].style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
      (marker as any).dom.children[1].style.fontSize = '14px';
      (marker as any).dom.children[1].style.fontWeight = '400';
      (marker as any).dom.children[1].style.color = '#FFFFFF';
      (marker as any).dom.children[1].style.transform = 'translate3d(0, -5px, 0)'
      marker.on('click', (e: any) => {
        console.log('e', e)
        const data = e.target.getExtData()
        console.log('点位信息', data)
        this.loadsignList(data.objectId)
      })
    })
    this.map.add(markers)
  }

  loadsignList (facilityId: any) {
    this.dialogVisible = true
    this.$axios.get(this.$apis.grid.selectPoint30SignList, {
      facilityId
    }).then((res) => {
      this.signList = res || []
    })
  }
}
